import React, { Component } from "react";
import PropTypes from "prop-types";
import anime from "animejs";
class StorySvg extends Component {

  render () {
    const { visuals } = this.props;

    const blob_left_style = {
      transform: [
        `translateX(${visuals.blob_left.translateX.from})`,
        `translateY(${visuals.blob_left.translateY.from})`,
        `scale(${visuals.blob_left.scale.from})`,
        `rotate(${visuals.blob_left.rotate.from})`
      ].join(" ")
    };

    return (
      <svg className="venn-figure-svg">
        <g>
          <g fill="#de4826">
            <path
              id="blob_left"
              style={blob_left_style}
              d={visuals.blob_left.path_d.from}
              fillRule="evenodd"
            />
          </g>
          <g fill="#3ac1cc">
            <path
              className="blob_right"
              d={visuals.blob_right.path_d.from}
              fillRule="evenodd"
            />
          </g>
        </g>
      </svg>
    )
  }

}

StorySvg.propTypes = {
  visuals: PropTypes.object.isRequired
};

export default class StorySectionHero extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isMounted: false
    };
  }

  initVisuals = () => {
    const isMobile = window.matchMedia("screen and (max-width: 768px)").matches;
    console.log("isMobile:",isMobile);
    this.visuals = {
      // here 'from' means initial render state, 'to' - is final animation state
      blob_left: {
        path_d: {
          from: "M288.839114,107.253632 C325.385758,166.53965 348.540606,237.492742 323.571386,290.124207 C298.688564,342.66925 225.595276,376.806243 161.746647,371.448032 C97.8980173,366.176243 43.2940476,321.322826 17.5472391,268.34567 C-8.11317079,215.368514 -4.91641939,154.094771 22.4719642,100.166965 C49.7739491,46.2391577 101.094769,-0.42913672 151.724399,0 C202.354029,0.435090954 252.29247,47.967613 288.839114,107.253632 Z"
        },
        ...(isMobile ? {
          translateX: { from: "-29.2%", to: "8.6%" },
          translateY: { from: "-8%", to: "22.9%" },
          scale: { from: "0.55", to: "0.55" },
          rotate: { from: "-25deg", to: "-3deg" }
        } : {
          translateX: { from: "-5.2%", to: "22.1%" },
          translateY: { from: "-24%", to: "13%" },
          scale: { from: "1", to: "1" },
          rotate: { from: "0deg", to: "0deg" }
        })
      },
      blob_right: {
        path_d: {
          from: "M654.751826,186.050942 C706.35087,271.911099 731.521136,380.207964 719.075838,489.211497 C706.770375,598.391697 656.151375,710.341362 590.009511,733.661404 C523.867647,756.981447 467.819918,696.5035 375.453293,633.027182 C283.086669,569.550864 181.830507,543.801597 143.375935,452.288096 C104.921362,360.597928 116.947156,219.61767 176.09728,131.637509 C235.107568,43.6573482 341.242187,8.67728414 433.253491,22.8106433 C525.264794,36.9440025 603.152782,100.190785 654.751826,186.050942 Z"
        },
        ...(isMobile ? {
          translateX: { from: "-1%", to: "12%" },
          translateY: { from: "18.7%", to: "43.7%" },
          scale: { from: "0.53", to: "0.53" },
          rotate: { from: "0deg", to: "0deg" }
        } : {
          translateX: { from: "99%", to: "39.5%" },
          translateY: { from: "-99%", to: "14.5%" },
          scale: { from: "1", to: "1" },
          rotate: { from: "76deg", to: "0deg" }
        })
      },
      smiling_text: {
        ...(isMobile ? {
          translateX: { from: "-4%", to: "-4%" },
          translateY: { from: "10.2%", to: "10.2%" },
          scale: { from: "0.83", to: "0.83" },
          rotate: { from: "101.7deg", to: "37.8deg" },
          transformOrigin: { from: "53.3% 32.5%", to: "48.8% 31.7%" }
        } : {
          translateX: { from: "25.2%", to: "24.6%" },
          translateY: { from: "19.4%", to: "17.4%" },
          scale: { from: "1.35", to: "1.357" },
          rotate: { from: "27deg", to: "0deg" },
          transformOrigin: { from: "15.7% 30.3%", to: "15.7% 30.3%" }
        })
      },
      cbd_text: {
        ...(isMobile ? {
          translateX: { from: "52.9%", to: "52.9%" },
          translateY: { from: "76.2%", to: "76.2%" },
          scale: { from: "0.85", to: "0.85" },
          rotate: { from: "66.8deg", to: "24.1deg" },
          transformOrigin: { from: "4.2% -18.2%", to: "-8% -31.8%" }
        } : {
          translateX: { from: "53.7%", to: "56.3%" },
          translateY: { from: "61.1%", to: "63.4%" },
          scale: { from: "1.35", to: "1.36" },
          rotate: { from: "-27deg", to: "0deg" },
          transformOrigin: { from: "4.2% -18.2%", to: "4.2% -18.2%" }
        })
      }
    };
  };

  componentDidMount () {
    this.initVisuals();
    requestAnimationFrame(() => {
      this.setState({ isMounted: true }, () => {
        this.createAnimationTimeline();
      });
    });
  }
  render () {
    return (
      <section id="story-section-hero" className="story-section">
        {this.state.isMounted && <StorySvg visuals={this.visuals} />}
        <div id="story-hero" className="hero is-fullheight">
          <div className="hero-body">
          <div className="container">
            <div >
              <div className="has-text-light hero-text has-text-centered">
                <h1 className="has-text-light">
                  Why we do<br />
                  what we do
                </h1>
                <p>Smile is a unique CBD brand dedicated to keeping you present, peaceful, and in the zone.</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
    );
  }

  createAnimationTimeline () {

    const visuals = this.visuals;

    // targets
    const blob_left = document.querySelector(".venn-figure-svg #blob_left");
    const blob_right = document.querySelector(".venn-figure-svg #blob_right");
    const smiling_text = document.querySelector(".venn-figure-svg #smiling_text");
    const cbd_text = document.querySelector(".venn-figure-svg #cbd_text");
    const text = document.querySelector(".venn-figure #venn-figure-text");


    const arcTextEasing = "easeInQuad";

    // timing
    const totalDuration = 1000;
    const blobDuration = Math.round(totalDuration * 0.7);
    const textDuration = Math.round(totalDuration * 0.3);
    const textDelay = blobDuration;

    const easing = "linear";
    const elasticity = 0;

    const animation_timeline = anime.timeline({
      easing,
      duration: totalDuration,
      autoplay: false
    });

    // ANIMATE LEFT BLOB
    animation_timeline.add({
      targets: blob_left,
      elasticity,
      easing,
      duration: blobDuration,
      d: { value: visuals.blob_left.path_d.to },
      translateX: visuals.blob_left.translateX.to,
      translateY: visuals.blob_left.translateY.to,
      scale: visuals.blob_left.scale.to,
      rotate: visuals.blob_left.rotate.to
    }, 0);

    // ANIMATE RIGHT BLOB
    animation_timeline.add({
      targets: blob_right,
      elasticity,
      easing,
      duration: blobDuration,
      d: [{ value: visuals.blob_right.path_d.to }],
      translateX: visuals.blob_right.translateX.to,
      translateY: visuals.blob_right.translateY.to,
      scale: visuals.blob_right.scale.to,
      rotate: visuals.blob_right.rotate.to
    }, 0);

    // ANIMATE BLOBS TEXT
    animation_timeline.add({
      targets: text,
      opacity: 1,
      duration: textDuration,
      easing: "easeInQuad"
    }, textDelay);

    // ANIMATE SMILING TEXT
    animation_timeline.add({
      targets: smiling_text,
      opacity: 1,
      duration: textDuration,
      easing: arcTextEasing,
      translateX: visuals.smiling_text.translateX.to,
      translateY: visuals.smiling_text.translateY.to,
      scale: visuals.smiling_text.scale.to,
      rotate: visuals.smiling_text.rotate.to,
      transformOrigin: visuals.smiling_text.transformOrigin.to
    }, textDelay);

    // ANIMATE CBD TEXT
    animation_timeline.add({
      targets: cbd_text,
      opacity: 1,
      duration: textDuration,
      easing: arcTextEasing,
      translateX: visuals.cbd_text.translateX.to,
      translateY: visuals.cbd_text.translateY.to,
      scale: visuals.cbd_text.scale.to,
      rotate: visuals.cbd_text.rotate.to,
      transformOrigin: visuals.cbd_text.transformOrigin.to
    }, textDelay);

    this.animation_timeline = animation_timeline;
  }

  animate = () => {
    this.animation_timeline.play();
  };

  play = () => {
    if (this.animation_timeline.reversed) {
      this.animation_timeline.reverse();
    }
    this.animation_timeline.play();
  };

  back = () => {
    if (this.animation_timeline.completed) {
      this.animation_timeline.reverse();
      this.animation_timeline.completed = false;
      this.animation_timeline.play();
    } else {
      this.animation_timeline.reverse();
    }
  };
}
