import React, { Component } from "react";
import SEO from "../components/seo";
import LayoutFullpage from "../components/layout-fullpage";
import Header from "../components/header";
import Footer from "../components/footer";
import StoryVennBg from "../components/story/story-venn-bg";
import StorySectionHero from "../components/story/story-section-hero";
import StorySectionVennEmpty from "../components/story/story-section-venn-empty";
import StorySectionLines from "../components/story/story-section-lines";
import StorySectionImages from "../components/story/story-section-images";
import StorySectionBenefits from "../components/story/story-section-benefits";
import StorySectionBestSmile from "../components/story/story-section-best-smile";
import QuickATCSection from "../components/home/quick-atc-section";
import QuickATCSectionMobile from "../components/home/quick-atc-section-mobile";
import NotifyMeModal from "../components/product/notify-me-modal";
import yotpo from "../lib/yotpo";

// story
import "./story.scss";

class StoryPage extends Component {
  constructor (props) {
    super(props);
    const products = props.data.allWcProducts.edges;
    this.state = {
      products: products,
      isCartModalOpen: false,
      newCartItem: null,
      localCart: {},
      allProductBottomLine: []
    };
    this._vennBg = React.createRef();
    this._linesSection = React.createRef();
    this.onSectionLeave = this.onSectionLeave.bind(this);
    this.afterSectionLoad = this.afterSectionLoad.bind(this);
    this._notifyMeModal = React.createRef();
  }

  componentWillUnmount () {
    // Remove these elements from DOM: header#masthead, #story-section-lines
    // cause it was moved to body append by fullpage plugin
    document.querySelector("header#masthead").remove();
    document.getElementById("story-section-lines").remove();
    console.log("fixed elements removed");
  }
  componentDidMount () {
    if(this.state.allProductBottomLine.length === 0){
      yotpo.getAllProductsBottomLine()
      .then(allProductBottomLine => {
        this.setState({allProductBottomLine:allProductBottomLine})
      })
    }
  }
  handleCartModalClose = () => {
    this.setState({ isCartModalOpen: false })
  }
  handleCartModalOption = () => {
    this.setState({ isCartModalOpen: true })
  }
  addNewCartItem = (localCart, item) => {
    this.setState({ newCartItem: item, localCart: localCart })
  }

  notifyMeClick = () => {
    this._notifyMeModal.current.open();
  }
  render () {
    /*
    let mobileProducts = this.state.products.filter(p => (p.node.stock_status === "instock"));
    if(mobileProducts[0].node.slug.indexOf("topicals") !== -1){
      mobileProducts.push(mobileProducts.splice(0, 1)[0]);
    }
    */
    return (
      <div className="is-overflow-hidden">
      <LayoutFullpage isCartModalOpen={this.state.isCartModalOpen} handleCartModalClose={this.handleCartModalClose} newCartItem={this.state.newCartItem} localCart={this.state.localCart}>
        <SEO title="Our Story" description="Did you know that smiling and CBD have many of the same health benefits? Discover our story and learn why we are passionate about our CBD products at Reason to Smile." />   
        <Header isAlternate={true} />
        <StorySectionHero />
        <StorySectionVennEmpty />
        <div className="story-section fp-auto-height">
          <StoryVennBg ref={this._vennBg} />
          <StorySectionLines ref={this._linesSection} />
          <StorySectionImages />
          <StorySectionBenefits />
          <StorySectionBestSmile />
          <QuickATCSection products={this.state.products} handleCartModalOption={this.handleCartModalOption} addNewCartItem={this.addNewCartItem} allProductBottomLine={this.state.allProductBottomLine} notifyMeClick={this.notifyMeClick}/>
          <QuickATCSectionMobile products={this.state.products} handleCartModalOption={this.handleCartModalOption} addNewCartItem={this.addNewCartItem} allProductBottomLine={this.state.allProductBottomLine} notifyMeClick={this.notifyMeClick}/>
          <Footer />
        </div>   
        <NotifyMeModal ref={this._notifyMeModal} />
      </LayoutFullpage>
      </div>
    );
  }

  onSectionLeave (origin, destination, direction) {
    const linesSection = document.getElementById("story-section-lines");

    // play venn blobs animation
    if (origin.item.id === "story-section-hero" && direction === "down") {
      this._vennBg.current.play();

      // hide fixed header
      document.querySelector("header#masthead").style.transform = "translateY(-100vh)";
    }

    // reverse venn blobs animation
    else if (origin.item.id === "story-section-venn-empty" && direction === "up") {
      this._vennBg.current.back();

      // show fixed header
      document.querySelector("header#masthead").style.transform = "none";
    }

    // <editor-fold desc="fixed lines section moving">
    else if (destination.item.id === "story-section-lines-scroll-trigger-1" && direction === "down") {
      // from bottom outside → to viewport
      linesSection.classList.remove("toDown");
      linesSection.classList.add("toViewport");
    } else if (origin.item.id === "story-section-lines-scroll-trigger-1" && direction === "up") {
      // from viewport → to bottom outside
      linesSection.classList.remove("toViewport");
      linesSection.classList.add("toDown");
    } else if (origin.item.id === "story-section-lines-scroll-trigger-3" && direction === "down") {
      // from viewport → to top outside
      linesSection.classList.remove("toViewport");
      linesSection.classList.add("toUp");
    } else if (destination.item.id === "story-section-lines-scroll-trigger-3" && direction === "up") {
      // from top outside → to viewport
      linesSection.classList.remove("toUp");
      linesSection.classList.add("toViewport");
    }
    // </editor-fold>

    
  }

  afterSectionLoad (origin, destination, direction) {
    //
  }
}

export default StoryPage;
export const pageQuery = graphql `
  query {
      allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, tags: {elemMatch: {name: {eq: "CBD"}}}}) {
      edges {
        node {
          id
          type
          wordpress_id
          slug
          name
          price
          stock_status
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          description
          grouped_products_nodes {
            id
            wordpress_id
            sku
            name
            price
            regular_price
            slug
            type
            images {
                id
                alt
                src
            }
            categories {
                wordpress_id
                name
            }
          }
        }
      }
    }
  }
`