import React, { Component } from "react";

export default class TextBlock1 extends Component {
  render () {
    return (
      <div className="text-block">
        <h3 className="has-text-primary">CBD & SMILING are both natural mood boosters</h3>
        <p style={{marginTop:"10px"}}>A 2010 study found that making yourself smile when you’re feeling down helps improve your mood functions and increases positive thoughts. CBD is a phytocannabinoid which helps to stimulate the regulation of the central nervous system. CBD, therefore, helps supplement the effects of endocannabinoids in regulating appetite, mood, functions of the immune system, sensation, and keeping our bodies working normally.</p>
      </div>
    );
  }
}
