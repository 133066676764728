import React, { Component, Fragment } from "react";
import AnimatedLines from "../animatedLines";
import { isBrowser } from "react-device-detect";
import Slider from "react-slick";
import "./story-section-lines.scss";
import TextBlock1 from "./story-section-lines/text-block-1";
import TextBlock2 from "./story-section-lines/text-block-2";
import TextBlock3 from "./story-section-lines/text-block-3";

const StorySectionLinesBg = () => {
  return (
    <div id="story-section-lines-bg" className="section-bg">
      <AnimatedLines className="lines-01" style={{
        transform: "scaleX(1) rotate(130deg)",
        position: "absolute",
        top: "-10%",
        left: isBrowser ? "-10%" : "-90%",
        zIndex: "0",
        width: "592px"
      }} />
      <AnimatedLines className="lines-02" style={{
        position: "absolute",
        transform: "scaleX(-1) rotate(50deg)",
        top: isBrowser ? "30%" : "50%",
        right: isBrowser ? "-20%" : "-120%",
        zIndex: "0",
        width: "655px"
      }} />
    </div>
  );
};

export default class StorySectionLines extends Component {

  constructor (props) {
    super(props);
    this.state = {
      activeTextNum: 1,
      autoplay: false
    };
  }

  render () {
    const SamplePrevArrow = (props) => {
      const { className, style, onClick } = props;
      return (<div className={className} style={{
        ...style,
        display: "block"
      }} onClick={onClick} />);
    };
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 6000,
      autoplay: this.state.autoplay,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />
    };

    return (
      <section className="section is-small has-white-background is-relative">
      <Fragment>
        <div id="story-section-lines">
          <section className="section is-medium is-relative">
            <div className="container">
              <div className="columns is-vcentered">
                <div className="column is-6 is-hidden-mobile">
                  <div className="image-wrapper">
                    <figure className="image is-square">
                      <img src="https://reasontosmile.wpengine.com/wp-content/uploads/2019/10/model-tank-top-1.png" alt="cbd oil near me" />
                    </figure>
                  </div>
                </div>
                <div className="column is-5">
                  <h2>Smiling and CBD have many of the same health benefits</h2>
                  <h4>Research has shown that both CBD & the physical act of smiling have powerful effects on your mental health and overall well-being. We decided to do a deep dive and were blown away by the results.</h4>
                  <br />
                  <Slider ref={slider => (this.slider = slider)} {...settings}>
                    <TextBlock1/>
                    <TextBlock2/>
                    <TextBlock3/>
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
      </section>
    );
  }

  showText (num) {
    this.setState({ activeTextNum: num });
  }

}
