import React, { Component } from "react";

export default class TextBlock2 extends Component {
  render () {
    return (
      <div className="text-block">
        <h3 className="has-text-primary">Help counter everyday stress</h3>
        <p style={{marginTop:"10px"}}>Supports the body systems that regulate your responses to stressful stimuli. By checking your blood pressure, providing hormonal balance & adrenal support, regular use may lead to a more peaceful headspace.</p>
      </div>
    );
  }
}
