import React, { Component } from "react";
import { isBrowser } from "react-device-detect";

export default class StorySectionBestSmile extends Component {

  render () {
    return (
      <section id="story-section-best-smile" className="section has-white-background is-relative">
        
        <div className="container" style={{ zIndex: 1, marginBottom:'70px' }}>
          <div className="columns is-centered is-vcentered">
            <div className="column is-6 is-offset-3 has-text-centered">
              <h2>Only the best to make you smile</h2>
              <h4 style={{padding: '15px 0',lineHeight: '1.6'}}>We worked hard on our innovative formulas. Each contains our proprietary formula of CBD with hand-selected wellness supplements for unique (& incredible) final products. We love them, and we know you will too.</h4>
            </div>
            <div className="column is-3"></div>
            
          </div>
        </div>
      </section>
    );
  }
}
