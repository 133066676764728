import React, { Component } from "react";

export default class StorySectionVennEmpty extends Component {
  render () {
    return (
      <section id="story-section-venn-empty" className="story-section">
        {/*empty screen*/}
      </section>
    );
  }

}
