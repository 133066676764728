import React from "react";
import Highlightable from "../highlightable";

export default function StorySectionImages () {
  return (
    <section id="story-section-images" className="section is-relative has-white-background">
      <div className="container">
        <div className="columns is-vcentered is-centered is-reversed-mobile">
          <div className="column is-5">
            <h3 className="has-text-weight-bold">
              <Highlightable>We’re on a mission</Highlightable>
            </h3>
            <h2>Smile is all about helping you find a sense of balance</h2>
            <h4>We are dedicated to developing CBD products made with high-quality ingredients that empower you to take control over your mindstate & find a sense of inner clarity, peace and flow. We want to make sure smiling is a daily part of your life.</h4>
          </div>
          <div className="column is-relative happiness-inside-image is-6 is-offset-1" style={{
            backgroundImage: `url(https://reasontosmile.wpengine.com/wp-content/uploads/2020/02/P34u7R7x.jpeg)`
          }}/>
        </div>
        <div className="columns is-vcentered is-centered" style={{marginTop:'100px'}}>
          <div className="column is-6">
            <div className="image cbd-oil-weed" />
          </div>
          <div className="column is-5 is-offset-1">
            <h2>Pure and potent CBD,<br />for the win</h2>
            <h4>Hemp is one of the most nutritious plants in the world. Our CBD isolate has cannabinoids that play an essential role in our bodies’ endocannabinoid system, which works to regulate a wide range of functions & processes: sleep, appetite, mood and memory, to name a few.</h4>
          </div>
        </div>
      </div>
      <img id="spinning-text" src={require("../../images/story/animated-txt.svg")} alt="" />
    </section>
  );
}
