import React, { Component } from "react";
import { isBrowser } from "react-device-detect";
import Benefits from "../benefits";
import AnimatedLines from "../animatedLines";
import "./story-section-benefits.scss";

export default class StorySectionBenefits extends Component {
  render () {
    return (
      <section id="story-section-benefits" className="section is-medium is-relative has-white-background">
        <div className="section-bg">
          <AnimatedLines className="lines-01" style={{
            transform: "scaleX(1) rotate(10deg)",
            position: "absolute",
            top: isBrowser ? "50%" : "65%",
            left: isBrowser ? "-10%" : "-100%",
            zIndex: "20",
            width: "592px"
          }} />
          <AnimatedLines className="lines-02" style={{
            transform: "rotate(0deg)",
            position: "absolute",
            top: isBrowser ? "5%" : "-10%",
            right: isBrowser ? "-18%" : "-130%",
            zIndex: "1",
            width: "654px"
          }} />
        </div>
        <div className="container">
          <div className="columns is-centered is-multiline">
            <div className="column is-7 has-text-centered">
              <h1>Just The Good Stuff Without The High Feeling</h1>
            </div>
          </div>
          <div className="columns is-centered is-multiline">
            <div className="column is-6 has-text-centered">
              <h4>Each CBD product is 100% CBD with no THC, so you don’t have to worry about feeling high. We also say no to gluten, allergens, artificial flavors, GMO, animal-derived ingredients and animal testing for a totally responsible final product.</h4>
            </div>
          </div>
          <Benefits />
        </div>
      </section>
    );
  }
}
