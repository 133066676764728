import React, { Component } from "react";

export default class TextBlock3 extends Component {
  render () {
    return (
      <div className="text-block">
        <h3 className="has-text-primary">Support recovery from exercise</h3>
        <p style={{marginTop:"10px"}}>Anti-inflammatory properties prevent joint injury during exercise and reduce pain from swelling. Cannabinoids are known to have antispasmodic properties, which work to reduce the frequency and duration of muscle spasms. Regular use may also speed up the body’s natural recovery time.</p>
      </div>
    );
  }
}
